#main-search {
    position: relative;
    top:0;
    min-height:100%;
    overflow: hidden;
    width:100%;
}

.main-search-btn {
    position:absolute;
    top:10px;
    right:10px;

}

#main-search input[type='search']
{
    height: 4rem;
    margin: 0;
    padding-left: 25px;
    width: calc( 100% - 25px);
    border-radius: 150px;
    transition: all .3s;
    box-shadow: 0 0 0 rgba(0,0,0,.2);
    border:1px solid rgba(0,0,0,.2);
    background: #FFFFFF;
}

#main-search input[type='search']:hover, #main-search input[type='search']:focus, #main-search input[type='search']:active
{
    height: 4rem;
    margin: 0;
    padding-left: 25px;
    width: calc( 100% - 25px);
    border-radius: 150px;
    background: white;
    box-shadow: 0 0 10px rgba(0,0,0,.2);
}

#main-search input[type='search']::placeholder
{
    color: #333333;
}