#product-image-container
{
    width:100%;
    height:600px;

    background-size: cover !important;
    border-radius:.5em;
}

#product-prices-table tr td, #product-prices-table tr th
{
    padding: 0;
}

#logo-devis
{
    width:150px;
    margin:auto;
    position:absolute;
    right: 20px;
    z-index: 0;
    transform: translateY(2%);
}

#product-page #brand-name {
    font-weight: 600;
}

#product-page #product-name {
    font-weight: 700;
    font-size: 2rem;
}

#product-page #product-default-price {
    font-weight: 700;
    font-size: 2.5rem;

    color: #333333;
}

#product-info-card {
 border: 1px solid #dedede;
    box-shadow: none;
    border-radius: .5em;
}

.product-image-btn {
    width: 100px;
    height:100px;
    border: 1px solid #dedede;
    border-radius: .5em;
    background-size: cover !important;
}



.small-text {
   font-size: 1rem;
    color:#555555;
    font-weight: 400;
}