.product-card
{
    border: 1px solid #dddddd;
    box-shadow: 0px 0px 15px rgba(0,0,0,0.1);
    transition: all 0.5s, background-color 0.2s;
    border-radius: 0.5em;
    overflow: hidden;
}

.product-card:hover
{
    /*cursor: pointer;*/
    border: 1px solid #BBBBBB;
    border-radius: 0.5em;
    /*box-shadow: 0px 0px 30px rgba(0,0,0,0.2);*/
    box-shadow: 0px 0px 10px rgba(0,0,0,0.5);
    background: #FFFFFF;
    transition: all 0.5s !important;

}


.product-card-image
{
    width:100%;
    height:200px;
    background-size: cover;
    background-position: center;
    /*border:2px solid #00aeff;*/
    background-color: #FFFFFF;
    transition: background-size 2s ease-in;
    -moz-transition: background-size 2s ease-in;
    -ms-transition: background-size 2s ease-in;
    -o-transition: background-size 2s ease-in;
    -webkit-transition: background-size 2s ease-in;
    border-radius: 0.5em 0.5em 0 0;
}

.product-card-image:hover
{
    background-size: auto 120%;
}




.product-card:hover .product-card-image
{
    width:100%;
    height:200px;
    background-position: center;
    /*border-bottom:2px solid #00aeff;*/
    background-color: #FFFFFF;
}


.product-card .product-card-name
{
    font-weight: 400;
    font-size: 1.1rem;
    margin:0;
}

.product-card .product-card-code
{
    font-weight: 400;
    font-size: 0.8rem;
    color:#666666;
    margin:0;
}

.product-card .product-card-description
{
    font-weight: 400;
    font-size: 1rem;
    margin-top: 2%;
    margin-bottom: 0;
    text-align: center;
    color:#555555;
}
.product-card .product-card-brand
{
    font-weight: 700;
    font-size: 0.8rem;
    color:#AAAAAA;
    text-align: center;
    margin:0;
}

.product-card .product-card-price
{
    font-weight: 700;
    text-align: center;
    font-size: 1.5rem;
    color:#333333;
    margin-top:2%;
    margin-bottom:2%;
}


