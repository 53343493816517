

html
{
    font-family: 'Nunito', sans-serif;
    font-weight: normal;
    line-height: 1.5;
    color: #333333;
}


body {
    background: #FFFFFF;

}


#header-logo
{
    max-width:100%;
}

.front-card
{
    border: 1px solid #dddddd;
    box-shadow: 0px 0px 20px rgba(0,0,0,0.2);
}

.card-title
{
    background: #1565C0;
    margin: 0;
    text-transform: uppercase;
    /*border: 2px solid #1a237e;*/
    /*border-bottom: 3px solid #1a237e;*/
    /*border-bottom: 3px solid #ffeb3b;*/
    color: #FFFFFF;
    font-size:1.4rem;
    padding:12px;
    border-radius: 0.2em 0.2em 0 0;
}

.front-subtitle {
    position: relative;
    margin-bottom:26px !important;
    font-weight: 600;
    font-size: 1.8rem;
    padding-left:12px;
    color: #333333;
}

.front-subtitle:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 5px;
    border-radius: 0.3em;
    background-color: #e53935;
}


.front-subtitle-center {
    position: relative;
    margin-bottom:26px !important;
    font-weight: 600;
    font-size: 1.8rem;
    padding-left:12px;
    text-align:center;
    color: #333333;
}

.front-subtitle-center:after {
    content: "";
    position: absolute;
    top: inherit;
    left: 50%;
    bottom: -10px;
    height: 6px;
    border-radius: 0.3em;
    width: 50px;
    background-image: none;
    background-color: #e53935;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}



.right-banner
{
    width:30%;
   display: block;
    box-shadow: 0px 0px 30px rgba(0,0,0,.16);
    position: relative;
}

.right-banner:before
{
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 8px;
    width: 55px;
    border-radius: 0.3em;
    background-color: #FF0000;
    z-index:999;

}






