
.sidenav {
    position: fixed;
    width: 70%;
    left: 0;
    top: 0;
    margin: 0;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    height: 100%;
    height: calc(100% + 60px);
    height: -moz-calc(100%);
    padding-bottom: 60px;
    background-color: #fff;
    z-index: 999;
    overflow-y: auto;
    will-change: transform;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: translateX(-105%);
    transform: translateX(-105%);
}

.sidenav .logo
{
    width:80%;
    display: block;
    margin: auto;
}
