
.tabs
{

    background:transparent;
    font-weight: bold;
}


.tabs .tab a{
    color:#0d47a1;
} /*Black color to the text*/

.tabs .tab a:hover {
    /* background-color:#0d47a1; */
    color:#0d47a1;
} /*Text color on hover*/

.tabs .tab a.active {
    background-color:#ffffff;
    color:#0d47a1;
} /*Background and text color when a tab is active*/

.tabs .indicator {
    background-color:#1a237e;
    height:3px;
} /*Color of underline*/

.tabs .tab a:focus, .tabs .tab a:focus.active {
    background-color:#ffffff;
    color:#0d47a1;
    outline: none;
}

.tabs .tab.disabled a, .tabs .tab.disabled a:hover {
    color: #dddddd;
    cursor: default;
}