.left-card-categories
{

}
#left-card-categories ul
{
    padding:0 !important;
}

#left-card-categories ul li
{
    margin: 3px 12px 3px 0;
    padding: 6px 4px 6px 4px;

}
#left-card-categories ul li a
{
    color: #1565C0;
    display: block;
}

#left-card-categories ul li:hover, #left-card-categories ul li.active
{
    background: #1565C0;
    /*background: #e53935;*/
    color:#FFFFFF;
    margin: 3px 12px 3px -8px;
    padding: 6px 4px 6px 12px;
    border-radius: 0.5em 0.5em 0.5em 0.5em;
    box-shadow: 2px 0px 30px rgb(21, 101, 192,0.5);

}

#left-card-categories ul li.active a, #left-card-categories ul li:hover a
{
    color:#FFFFFF;
}

.category-link-btn:hover
{
    background:#e3f2fd;
}

