#mobile-menu-trigger {
    position: absolute;
    top: 2%;
    left: 2%;
    border: 1px solid #000000;
    border-radius: 0.3em;
}

#mobile-menu-trigger i{
    font-size: 5rem;
}

#mobile-account-btn {
    position: absolute;
    top: 2%;
    right: 2%;
    border: 1px solid #000000;
    border-radius: 0.3em;
}

#mobile-account-btn i  {
    font-size: 5rem;
}

