#main-breadcrumb {

    height: 38px;
    box-shadow: none;
    line-height: 38px;
    background: transparent !important;
}

.breadcrumb {
    font-size:  .8rem;
    box-shadow: none;
    line-height: 38px;
    color: #333333;
}

.breadcrumb i {
    font-size:  1rem !important;
    box-shadow: none;
    line-height: 38px !important;
}
.breadcrumb:last-child {
    color: grey;
}



.breadcrumb::before {

    content: '\E5CC';
    color: rgba(0,0,0,0.7);
    vertical-align: top;
    display: inline-block;
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 12px;
    margin: 0 10px 0 8px;
    -webkit-font-smoothing: antialiased;

}
